<template>
	<div>
    <!-- <button @click="updateAllUserDays()">update</button> -->
    <div class="dashboard__container pt-3">
      <Loader v-if="performingRequest || (!myPipeline || myPipeline.length == '0')" />
      <div class="dashboard__container--body" v-if="myPipeline && myPipeline.length >= 1">
        <UserTable :groupUsers="sortedPipe" :showShiftPreference="false" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import * as moment from 'moment'
import router from '@/router'

export default {
  name: 'myPipeline',
  data() {
    return {
      performingRequest: false,
    }
  },
  created () {
    this.$store.dispatch('getMyPipeline')
  },
  components: {
    Loader,
    UserTable
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'myPipeline']),
    sortedPipe() {
      return this.myPipeline.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
  },
  beforeDestroy () {
    // this.$store.dispatch('clearMyPipeline')
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>